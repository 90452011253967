import React, { useState } from 'react'

export function DataItem({ item, handleUpdateItem, handleDeleteItem }) {

    const [action, setAction] = useState(null)
    const [title, setTitle] = useState('')

    function fnEdit(title_) {
        setTitle(title_)
        setAction("edit")
    }

    function fnEditSubmit() {
        setAction(null)
        handleUpdateItem(item,title)
    }
       
    return (
        <>
            <div>{item.id}</div>

            {!action ? (
                <>
                    <div style={{width:"200px"}}>{item.title}</div>
                    <div><button onClick={() => fnEdit(item.title)}>
                    <span role="img" aria-label="edit">🖊️</span></button>
                        <button onClick={()=>handleDeleteItem(item.id)} >
                            <span role="img" aria-label="del">❌</span>
                            </button></div>
                </>
            ) : (
                <>
                    <div><input type="text" value={title}
                        onChange={(e) => setTitle(e.target.value)} />
                    </div >
                    <div><button onClick={fnEditSubmit}>
                    <span role="img" aria-label="ok">✅</span></button>
                        <button onClick={()=>setAction(null)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
</svg></button></div>
                </>
            )}
        </>
    )
}