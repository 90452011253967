import { Data } from './Data.js'
import React from "react"

export default function Crud() {

  return (
    <div className='Crud'>
      <h2>CRUD</h2>
      <Data />
    </div>
  );
}
