import React, { useState, useEffect } from "react"
import Item from "./Item.jsx"

const Countries = () => {

    const api = "https://restcountries.com/v3.1/"
    const apiAll = api + "all"
    const [isLoading, setIsLoading] = useState(true)

    const [itemsIn, setItemsIn] = useState([])
    const [itemsOut, setItemsOut] = useState([])

    const [filter, setFilter] = useState([
        { title: "Africa", checked: true },
        { title: "Americas", checked: true },
        { title: "Antarctic", checked: true },
        { title: "Asia", checked: true },
        { title: "Europe", checked: true },
        { title: "Oceania", checked: true }
    ])

    const fnFilter = (items, filter) => {
        if (items === undefined
            || items.length === 0
            || filter.length === 0)
            return items

        items = items.filter(item => {
            if (item.region === undefined)
                return false

            let fi = filter.find(f => f.title === item.region)
            return fi.checked
        })
        console.log(items)
        return items
    }

    const fnSort = (items) => {
        return items.sort((d1, d2) => { return d1.name.common > d2.name.common ? 1 : 0 });
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const resp = await fetch(apiAll)
            let data = await resp.json()
            data = fnSort(data)
            setItemsIn(data)
            setItemsOut(data);
            // fetch(apiAll)
            // .then(resp => { return resp.json() })
            // .then(data => {setItems(data) })
            //return data
            setIsLoading(false)
        }
        fetchData();

        //setItemsOut(fnFilter(itemsIn,filterOut))
    }, [apiAll])

    useEffect(() => {
        let data = fnFilter(itemsIn, filter)
        setItemsOut(data)
    }, [filter, itemsIn])

    const onChangeFilter = (f_title) => {
        let filter_ = filter.map(fi => (
            fi.title !== f_title ? fi :
                { ...fi, checked: !fi.checked }
                
        )
        )
        setFilter(filter_)
    }

    return (
        <div className="Countries">
            <h2>Countries ({itemsOut.length} of 250)</h2>
            <hr />

            {filter.map((item, i) => {
                return (<>
                    <label>
                        <input type="checkbox" key={item.title}
                            value={item.title}
                            onChange={(e) => onChangeFilter(e.target.value)}
                            checked={item.checked}
                        />{item.title}</label> &nbsp;
                </>
                )
            })}
            <hr />

            {isLoading ? <p>Loading...</p> :
            itemsOut.map((item, i) => {
                return (
                    <Item item={item} key={item.name.common + i} />
                )
            })}
        </div>
    )
}

export default Countries;