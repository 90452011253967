import React, { useState, useEffect } from 'react';
import './Visual.css';
import imgGearFill from './img/gear-fill.svg';
import imgGear from './img/gear.svg';
import arrowsAngle from "./img/arrows-angle-expand.svg"
import imgShift from './img/shift.svg'
import imgShiftFill from './img/shift-fill.svg' 

//init
var rotationArray = [0, 90, 180, 270];
var rotationI = Math.floor(Math.random() * 4);
var error = { "cnt": 0, "min": 1000 };

var idCardHeight1 = 85.5;
let cookie = localStorage.getItem("idCardHeight");
if (cookie === null || cookie === undefined) {
} else {
  let cookieFloat = parseFloat(cookie);
  if (!isNaN(cookieFloat))
    idCardHeight1 = cookieFloat;
}
console.log(idCardHeight1);

var idCardWidth1 = 54;
cookie = localStorage.getItem("idCardWidth");
if (cookie === null || cookie === undefined) {
} else {
  let cookieFloat = parseFloat(cookie);
  if (!isNaN(cookieFloat))
    idCardWidth1 = cookieFloat;
}
console.log(idCardWidth1);

var isDivCfgVisibleGlobal = false;

var circleScale = 1;
cookie = localStorage.getItem("circleScale");
if (cookie === null || cookie === undefined) {
} else {
  let cookieFloat = parseFloat(cookie);
  if (!isNaN(cookieFloat))
    circleScale = cookieFloat;
}
console.log(circleScale);

var isConfigVisibleGlobal = false;

export default function Visual() {
  // Define styles for the credit card div
  const creditCardStyle = {
    height: '85.60mm',
    width: '53.98mm',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '0 auto'
  };

  // Define styles for the SVG circle
  const circleStyle = {
    width: '100px',
    height: '100px',
    // backgroundColor: 'red',
    // borderRadius: '50%',
    //position: 'absolute',
    //bottom: '20%', // Center vertically
    //transform: 'translateY(50%)' // Move it up by 50% of its height
  };

  // Define styles for the green box
  const toolBoxStyle = {
    width: '20px',
    height: '20px',
    // backgroundColor: 'green',
    color: 'silver',
    position: 'absolute',
    top: '2%',
    left: '10%', // Center horizontally
    transform: 'translateX(-50%)' // Move it left by 50% of its width
  };

  const logStyle = {
    position: 'fixed',
    bottom: 0, // Place at the bottom of the screen
    left: 0,
    width: '100%', // Full width
    padding: '10px',
    zIndex: -1,
    textAlign: "center"
  };


  //circle
  const [scale, setScale] = useState(circleScale);
  const [rotation, setRotation] = useState(rotationArray[rotationI]);

  const [logText, setLogText] = useState('');
  const [logColor, setLogColor] = useState('');
  // const updateLogText = (text,color) => {
  //   let str = '\n' + text;
  //   //setLogText(logText + str);
  //   setLogText(str);
  // };


  // const handleLogUpdate = (str,color) => {
  //   if (error["cnt"] >= 1) {
  //     let proc = error["min"] * 100;
  //     proc = proc.toFixed(2);
  //     let px = Math.round(200 * proc / 100);
  //     let str = proc + "% " + px + "px;";
  //     updateLogText(str);
  //     error["cnt"] = 0;
  //     error["min"] = 1000;
  //   }
  // };

  const handleLogUpdate = () => {
    let proc = scale * 100;
    proc = proc.toFixed(2);
    let px = Math.round(100 * proc / 100);
    let str = proc + "% " + px + "px;";
    setLogText(str);
    setLogColor(logColor);
    localStorage.setItem("circleScale", scale);
  };

  //divGear
  const [isHoveredCfg, setIsHoveredCfg] = useState(false);
  const [isHoveredUp, setIsHoveredUp] = useState(false);
  const [isHoveredDown, setIsHoveredDown] = useState(false);
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);

  const handleMouseOver = (btn) => {
    switch (btn) {
      case "Cfg": setIsHoveredCfg(true); break;
      case "Up": setIsHoveredUp(true); break;
      case "Down": setIsHoveredDown(true); break;
      case "Left": setIsHoveredLeft(true); break;
      case "Right": setIsHoveredRight(true); break;
    }
  };
  const handleMouseOut = (btn) => {
    switch (btn) {
      case "Cfg": setIsHoveredCfg(false); break;
      case "Up": setIsHoveredUp(false); break;
      case "Down": setIsHoveredDown(false); break;
      case "Left": setIsHoveredLeft(false); break;
      case "Right": setIsHoveredRight(false); break;
    }
  };
  //


  //scale Card
  const [isDivCfgVisible, setIsDivCfgVisible] = useState(false);
  const toggleCfg = () => {
    setIsDivCfgVisible(!isDivCfgVisible);
  };

  useEffect(() => {
    isDivCfgVisibleGlobal = isDivCfgVisible;
  }, [isDivCfgVisible]);

  const handleClick = (buttonName) => {
    //console.log(`App: "${buttonName}"`);
    console.log("handleClick():" + isDivCfgVisibleGlobal);
    if (!isDivCfgVisibleGlobal)
      processCircle(buttonName);
    else
      processScale(buttonName)
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleClick(event.key)
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function processCircle(event_key) {
    if (event_key !== 'ArrowLeft' && event_key !== 'ArrowRight'
      && event_key !== 'ArrowUp' && event_key !== 'ArrowDown')
      return;

    //console.log("processCircle:" + event_key);

    if (event_key === 'ArrowLeft' && rotationI == 0
      || event_key === 'ArrowRight' && rotationI == 2
      || event_key === 'ArrowUp' && rotationI == 1
      || event_key === 'ArrowDown' && rotationI == 3) {
      //handleLogUpdate("lightgreen");
      setLogColor("lightgreen");
      setScale((prevScale) => prevScale - 0.1 * prevScale);
    } else {
      if (scale < 1.2)
        setScale((prevScale) => prevScale * 1.1);
      //handleLogUpdate("lightcoral");
      setLogColor("lightcoral");

      // if (error["min"] > scale)
      //   error["min"] = scale;

      // error["cnt"]++;
    }

    console.log("Circle:" + scale);
    rotationI = Math.floor(Math.random() * 4);
    setRotation(() => rotationArray[rotationI]);
  }

  useEffect(() => {
    handleLogUpdate();
    //localStorage.setItem("circleScale", scale);
  }, [scale]);

  const [idCardHeight, setIdCardHeight] = useState(idCardHeight1); // Initial width of div
  const [idCardWidth, setIdCardWidth] = useState(idCardWidth1); // Initial width of div

  function processScale(event_key) {
    console.log("processScale:" + event_key);

    let incDec = 0;
    if (event_key === 'ArrowLeft' || event_key === 'ArrowUp')
      incDec = -1;
    else if (event_key === 'ArrowRight' || event_key === 'ArrowDown')
      incDec = +1;

    idCardHeight1 = idCardHeight1 + incDec * idCardHeight1 / 50;
    setIdCardHeight(idCardHeight1);
    localStorage.setItem("idCardHeight", idCardHeight1);
    console.log(idCardHeight1);

    idCardWidth1 = idCardWidth1 + incDec * idCardWidth1 / 50;
    setIdCardWidth(idCardWidth1);
    localStorage.setItem("idCardWidth", idCardWidth1);
    console.log(idCardWidth1);

    // idCardHeiight += 10;
  }


  return (
    <div className="Visual">
      <div id="idCard" style={{
        ...creditCardStyle
        , height: `${idCardHeight}mm`, width: `${idCardWidth}mm`
      }}>

        <img style={toolBoxStyle}
          src={isHoveredCfg || isDivCfgVisible ? imgGearFill : imgGear}
          onMouseOver={() => handleMouseOver("Cfg")}
          onMouseOut={() => handleMouseOut("Cfg")}
          onClick={() => toggleCfg()}
        />

        {!isDivCfgVisible &&
          <svg id="idCircle" style={{ ...circleStyle, transform: `scale(${scale}) rotate(${rotation}deg)` }}>
            <circle cx="50" cy="50" r="40" fill="white" stroke="black" strokeWidth="20" />
            <rect x="0" y="40" width="40" height="20" fill="white" />
          </svg>}
        {isDivCfgVisible && <img src={arrowsAngle} width="60px"
        />}


      </div>

      <div id='idNavi'>
        <div><img width="60px"
          onClick={() => handleClick("ArrowUp")}
          src={isHoveredUp ? imgShiftFill: imgShift }
          onMouseOver={() => handleMouseOver("Up")}
          onMouseOut={() => handleMouseOut("Up")}
        /></div>

        <div>
          <img width="60px"
            onClick={() => handleClick("ArrowLeft")}
            style={{ transform: 'rotate(270deg)' }}
            src={isHoveredLeft ?  imgShiftFill: imgShift}
            onMouseOver={() => handleMouseOver("Left")}
            onMouseOut={() => handleMouseOut("Left")}
          />
          <img width="60px"
            style={{ transform: 'rotate(180deg)' }}
            onClick={() => handleClick("ArrowDown")}
            src={isHoveredDown ? imgShiftFill: imgShift}
            onMouseOver={() => handleMouseOver("Down")}
            onMouseOut={() => handleMouseOut("Down")}
          />
          <img src={isHoveredRight ? imgShiftFill: imgShift}
            onMouseOver={() => handleMouseOver("Right")}
            onMouseOut={() => handleMouseOut("Right")}
            width="60px"
            onClick={() => handleClick("ArrowRight")}
            style={{ transform: 'rotate(90deg)' }} />
        </div>
      </div>

      <div style={{ ...logStyle, backgroundColor: logColor }} >{logText}</div>

    </div>
  );
}
