import React from "react"
import "./Home.css"
import { Link } from 'react-router-dom';
import imgCrud from './crudE.png'
import imgItems from './items.png'
import imgVisual from './visual.png'
import imgBooking from './booking.png'
import imgTimer from './timer.png'


export default function Home() {
    return (
        <>
            <div className="Home">
                <h2>Home</h2>

                <div className="grid-container">


                    <div>
                        <img src={imgBooking} width={200} />
                        <span>
                            <Link to="https://booking.encaps.click"><b>BooKing</b></Link> -
                            trains finder web application. Tech stack: React (front), HTML, CSS;
                            Java (back), gradle; infra - AWS (Route53, API Gateway, Lambda (Node.js), DynamoDB)
                        </span>
                        <div className="clear"></div>
                    </div>

                    <div>
                        <img src={imgCrud} width={200} />
                        <span>
                            <Link to="https://encaps.click"><b>CRUD</b></Link> is an acronym that stands for Create, Read, Update, and Delete. These are the four basic operations that can be performed on a data storage system
                        </span>
                        <div className="clear"></div>
                    </div>

                    <div>
                        <img src={imgVisual} width={200} />
                        <span>
                            <Link to="/visual/"><b>Vision</b></Link> -
                            the app to test visual acuity.
                            It shows you a symbol - you click corresponding arrows on the screen (mobile)
                            or on hardware keyboard (pc). If your answer is correct - it scales the symbol accordingly.
                            If you click the "gear" icon - you will be able to adjust/scale the "card" size to match credit card size (to get the same results on different screens)

                        </span>
                        <div className="clear"></div>
                    </div>
                    {/* <div className="clear"></div> */}

                    <div>
                        <img src={imgItems} width={200} />
                        <span>
                            <Link to="/countries/"><b>Countries</b></Link> -
                            the application to demonstrate how to get data from REST API https://restcountries.com/, and filter the results by Region
                        </span>
                        <div className="clear"></div>

                    </div>

                    <div>
                        <img src={imgTimer} width={200} />
                        <span>
                            <a href="/timer/index.html"><b>countdown timer</b></a> 
                        </span>
                        <div className="clear"></div>
                    </div>

                     <div className="clear">
<Link to="/tree">tree</Link>
&nbsp;<Link to="/crud">crud</Link>
&nbsp;<Link to="/countries/">countries</Link>

</div> 

                    {/* <div className="clear"></div> */}

                </div>
            </div>
        </>
    )
}

