// src/components/NavBar.js
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./NavBar.css"
import imgList from './list.svg'

const NavBar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleToggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <nav >
      <ul>
        <li><NavLink to="/home" className={({ isActive }) => (isActive ? 'active-link' : '')}>Home</NavLink></li>
        <li><Link to="https://booking.encaps.click" >booKing</Link></li>
        <li><Link to="https://encaps.click">CRUD</Link></li>
        <li><NavLink to="/visual" className={({ isActive }) => (isActive ? 'active-link' : '')}>Vision</NavLink></li>
      </ul>
      {/* <input type='text' placeholder='Search...' /> */}
    </nav >
  )
}

export default NavBar;
