import "./Item.css"
import React from  "react"

const Item = ({item})=>{

    const title = item.name.common
    return (
        <div className="Item">{title}</div>
    )

}

export default Item