import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './app/Home/Home.jsx';
import NavBar from './app/navi/NavBar.jsx';
import "./App.css"
import Countries from './app/items/Items.jsx'
import Crud from './app/crud/Crud.jsx'
import Visual from './app/visual/Visual.js'
import Tree from './app/tree/Tree.jsx'
import ItemCRUD from './app/ItemCRUD.jsx'


const App = () => (
  <BrowserRouter basename="/">
    <NavBar />
    <div className='App'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/countries" element={<Countries />} />
        <Route path="/crud" element={<Crud />} />
        <Route path="/visual" element={<Visual />} />
        <Route path="/tree" element={<Tree />} />
        <Route path="/crud1" element={<ItemCRUD />} />

      </Routes>
    </div>
  </BrowserRouter>
);

export default App;
