import data from './tree.json'
import Item from './Item.jsx'

export default function Tree() {

    const fnTree = (data) => {
        return (
            <ul>
                {data.map(item => (
                    <li id={item.id}>{item.title}
                        <ul>
                            {item.kids.length > 0 && fnTree(item.kids)}

                        </ul>
                    </li>
                ))}

            </ul>
        )
    }

    return (<>
        <h2>Tree</h2>
        <hr />
        {JSON.stringify(data)}
        <div className='grid-container'>
            <div>
                {data.map(item => (<Item key={item.id} id={item.id} title={item.title} kids={item.kids} />))}
            </div><div>
                {fnTree(data)}
            </div>
        </div>
    </>
    )
}