import React, { useState } from 'react';

const ItemCRUD = () => {
    const [items, setItems] = useState([]);
    const [item, setItem] = useState({ id: 0, field1: '', field2: 0 });
    const [editingIndex, setEditingIndex] = useState(null);
    const [nextId, setNextId] = useState(1); // Initialize nextId to 1

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem({ ...item, [name]: value });
    };

    const handleAddItem = () => {
        if (item.field1 && item.field2) {
            // Use nextId to set a unique ID for the new item
            setItems([...items, { ...item, id: nextId }]);
            setItem({ id: 0, field1: '', field2: 0 });
            setNextId(nextId + 1); // Increment nextId for the next item
        }
    };

    const handleEditItem = (id) => {
        const itemToEdit = items.find((i) => i.id === id);
        setItem(itemToEdit);
        setEditingIndex(id);
    };

    const handleUpdateItem = () => {
        if (item.field1 && item.field2 && editingIndex !== null) {
            const updatedItems = items.map((i) => (
                i.id === editingIndex ? item : i
            ));
            setItems(updatedItems);
            setItem({ id: 0, field1: '', field2: 0 });
            setEditingIndex(null);
        }
    };

    const handleDeleteItem = (id) => {
        setItems(items.filter((i) => i.id !== id));
    };

    return (
        <div>
            <h2>Item Management</h2>
            <input
                type="text"
                name="field1"
                placeholder="Enter field1"
                value={item.field1}
                onChange={handleInputChange}
            />
            <input
                type="number"
                name="field2"
                placeholder="Enter field2"
                value={item.field2}
                onChange={handleInputChange}
            />
            <button onClick={editingIndex !== null ? handleUpdateItem : handleAddItem}>
                {editingIndex !== null ? 'Update Item' : 'Add Item'}
            </button>
            <ul>
                {items.map((item) => (
                    <li key={item.id}>
                        {item.field1} (Field2: {item.field2})
                        <button onClick={() => handleEditItem(item.id)}>Edit</button>
                        <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ItemCRUD;
