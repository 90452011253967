import './Data.css'
import { DataItem } from './DataItem.js'
import React, { useState } from 'react'

export function Data() {

    const [action, setAction] = useState(null);
    const [title, setTitle] = useState('')
    const [items, setItems] = useState(
        [
            { id: 1, title: "asdf" },
            { id: 2, title: "asdf2" },
            { id: 3, title: "asdf3" },
        ]);

    // function fnUpdate(item_) {
    //     setItems(
    //         itemsOld => itemsOld.map(
    //             itemOld => {
    //                 if (itemOld.id === item_.id)
    //                     return item_
    //                 else
    //                     return itemOld;
    //             }
    //         )
    //     )
    // }

    // function fnAdd() {
    //     let item = { id: 0, title: title }
    //     setItems((items) => [...items, item])
    //     setAction("addSubmit")
    // }

    const handleAddItem = () => {
        if (title.trim() === '') return;
        const newItem = { id: Date.now(), title: title };
        setItems([...items, newItem]);
        setTitle('');
        setAction(null)
    };

    const handleDeleteItem= (id) => {
        setItems(items.filter(item => item.id !== id))
    }

    const handleUpdateItem = (item_, newTitle) => {
        setItems(
            items.map(item => (
            item.id === item_.id ? 
            { ...item, title: newTitle } 
            : item))
        );
    };

    return (
        <div className='grid'>
            <div className='head'>id</div>
            <div className='head'>title</div>
            <div className='head'></div>
            {items.map((item) => (
                <DataItem item={item} key={item.id} handleUpdateItem={handleUpdateItem} handleDeleteItem={handleDeleteItem} />
            ))}
            {action !== "add" ? (
                <>
                    <div ></div>
                    <div ></div>
                    <div ><button onClick={() => setAction("add")}>Add</button></div>
                </>
            ) : (
                <>
                    <div ></div>
                    <div ><input type="text" value={title}
                        onChange={(e) => setTitle(e.target.value)} /></div>
                    <div ><button onClick={handleAddItem}>Submit</button></div>
                </>)}
        </div>
    )
}